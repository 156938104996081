export const firstData = [
    {id: 1, src: '/images/history/c1.jpeg', title: '', desc: ''},
    {id: 2, src: '/images/history/c2.jpeg', title: '', desc: ''},
    {id: 3, src: '/images/history/c3.jpeg', title: '', desc: ''},
    {id: 4, src: '/images/history/c4.jpeg', title: '', desc: ''},
    {id: 5, src: '/images/history/c5.jpeg', title: '', desc: ''},
]

export const secondData = [
    {id: 6, src: '/images/history/c6.jpeg', title: '', desc: ''},
    {id: 7, src: '/images/history/c7.jpeg', title: '', desc: ''},
    {id: 8, src: '/images/history/c8.jpeg', title: '', desc: ''},
    {id: 9, src: '/images/history/c9.jpeg', title: '', desc: ''},
    {id: 10, src: '/images/history/c10.jpeg', title: '', desc: ''},
]

export const thirdData = [
    {id: 11, src: '/images/history/c11.jpeg', title: '', desc: ''},
    {id: 12, src: '/images/history/c12.jpeg', title: '', desc: ''},
    {id: 13, src: '/images/history/c13.jpeg', title: '', desc: ''},
    {id: 14, src: '/images/history/c14.jpeg', title: '', desc: ''}
]